// prettier-ignore
// import { Toolbar } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { Route, Router } from 'react-router-dom';
import { history } from './configureStore';
import { TrackMyTech } from './pages';
import { withRoot } from './withRoot';
import sbLogo from './images/sb-logo-rebrand.svg';
import { getQueryStringParams } from './utils';

function Routes() {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Route
        exact={true}
        path="/"
        render={props => (
          <TrackMyTech
            {...props}
            qsParams={getQueryStringParams(props.location.search)}
          />
        )}
      />
    </div>
  );
}

function App() {
  const classes = useStyles();

  return (
    <Router history={history as any}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <header>
            <div className={classes.appBar}>
              <img src={sbLogo} alt="Service Bench Logo"/>
            </div>
          </header>
          <Routes />
        </div>
      </div>
    </Router>
  );
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  appFrame: {
    width: '100%',
    height: '100%'
  },
  appBar: {
    borderTop: '5px solid #FFF',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 15,
    boxShadow: 'inset 0px -2px 0px #EFEFEF'
  },
  appLogo: {
    height: '3em'
  },
  drawerHeader: theme.mixins.toolbar,
  drawerPaper: {
    width: 250,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'relative',
      height: '100%'
    }
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: 'calc(100% - 56px)',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)'
    }
  }
}));

export default withRoot(App);