import React from 'react';
import { useDispatch } from 'react-redux';
import { calculateArrivalTime } from '../actionCreators/tmt';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import appConfig from '../config.json';
import destinationMarker from '../images/customerPin.svg';
import techLocationMarker from '../images/technicianPin.svg';

interface Coordinates {
  lat: number;
  lng: number;
}

export function GoogleMapContainer() {
  const dispatch: Function = useDispatch();

  const techCurrentLocation = useSelector(
    (state: RootState) => state.tmt.techLocation.currentTechLocation
  );

  const appointmentLocation = useSelector(
    (state: RootState) => state.tmt.appointmentDetails.appointmentLocation
  );

  const getGeocodedAddress: Function = (
    geocoder: google.maps.Geocoder
  ): Promise<google.maps.LatLng> => {
    return new Promise<google.maps.LatLng>((resolve, reject): void => {
      geocoder.geocode(
        { address: appointmentLocation },
        (results , status) => {
          if (results && status === 'OK') {
            resolve(results[0].geometry.location);
          } else {
            reject(
              'Geocode was not successful for the following reason: ' + status
            );
          }
        }
      );
    });
  };
  const handleApiLoaded: Function = async (
    map: google.maps.Map,
    maps: typeof google.maps
  ): Promise<any> => {
    // set map marker for tech's current location
    const { lat, lng }: Coordinates = techCurrentLocation;
    const techLocation: google.maps.LatLng = new maps.LatLng(lat, lng);

    new maps.Marker({
      position: techLocation,
      map: map,
      icon: techLocationMarker
    });

    // set tech location as map center in case tech location hasn't been set.
    map.setCenter(techLocation);

    // get service address latitude and longitude
    const geocoder: google.maps.Geocoder = new maps.Geocoder();
    const geocodedAddress: Coordinates = await getGeocodedAddress(geocoder);

    // set map marker for service address
    new maps.Marker({
      map: map,
      position: geocodedAddress,
      icon: destinationMarker
    });

    // set service address as map center in case tech location hasn't been set.
    map.setCenter(geocodedAddress);

    // create directions renderer and set options
    const directionsRenderer: google.maps.DirectionsRenderer = new maps.DirectionsRenderer(
      {
        polylineOptions: {
          strokeColor: '#333333',
          strokeWeight: 2
        },
        suppressMarkers: true
      }
    );
    directionsRenderer.setMap(map);

    // create directions service and render directions on map
    const directionsService: google.maps.DirectionsService = new maps.DirectionsService();
    directionsService.route(
      {
        origin: techLocation,
        destination: geocodedAddress,
        travelMode: google.maps.TravelMode.DRIVING,
        durationInTraffic: true
      },
      (
        result: google.maps.DirectionsResult| any ,
        status: google.maps.DirectionsStatus
      ) => {
        if (result && status === 'OK') {
          dispatch(
            calculateArrivalTime(result.routes[0].legs[0].duration_in_traffic)
          );
          directionsRenderer.setDirections(result);
        } else {
          console.log(
            'Directions Service was not successful for the following reason: ' +
              status
          );
        }
      }
    );
  };

  const classes = useStyles();

  // set map options
  const mapOptions: object = {
    disableDefaultUI: true
  };

  return (
    <div className={classes.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: appConfig.gMapsApiKey }}
        defaultCenter={{ lat: 38.916163, lng: -77.419244 }}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={mapOptions}
      ></GoogleMapReact>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  mapContainer: {
    height: 330,
    width: '100%',
    margin: '0 auto',
    borderRadius: '6px 6px 0px 0px',
    [theme.breakpoints.up('sm')]: {
      width: 300
    },
    '& div': {
      borderRadius: '6px 6px 0px 0px'
    }
  }
}));
